<template>
  <div>
    <div class="confirm-rattach" v-if="!connexionCourante">
      <img
        class="rattachement-bienvenue-img"
        src="../assets/img/successful-mark.svg"
        alt="cercle bleue avec une icone validé dedans"
      />
      <span class="confirm-rattach-message">Bienvenue sur Portailpro.gouv</span>
      <h1 class="mt-0 color-pr fs-b fs-2500 mb-5 titleRattachement">
        Rattachement de compte
      </h1>
    </div>
    <div class="bloc-rattachement row" v-if="!connexionCourante">
      <div class="col-sm-6 text-message">
        <div class="mb-3 text-message">
          Pour simplifier vos démarches, il ne vous reste plus qu'à rattacher
          les comptes des organismes auprès desquels vous avez des démarches à
          réaliser. Vous pourrez bientôt effectuer toutes vos démarches depuis
          ce portail commun !
        </div>
        <div class="text-message">
          Veuillez selectionner ci-dessous ceux avec lesquels vous êtes en
          relation.<br />
          Cette opération peut également être effectuée ultérieurement, à tout
          moment.
        </div>
      </div>
      <div class="col-sm-6 right-bloc-video">
        <div class="video">
          <img
            class="video-bloc"
            src="../assets/comptes.svg"
            alt="image avec le logo Urssaf/ impots.gouv.fr/ net-entreprise.fr/ douanes&droitsindirects"
          />
          <button
            class="playpause"
            title="Démarrer la vidéo - Youtube.com - Site externe - Ouverture dans un nouvel onglet"
            @click="redictionYT()"
          ></button>
        </div>
      </div>
      <div class="col-sm"></div>
    </div>
    <div class="titre-container" v-if="connexionCourante">
      <h1 class="mt-0 fs-b fs-2500 color-pr">Gestion des rattachements</h1>
    </div>
    <div class="bloc-copro">
      <div class="row each-row-copro">
        <BlocCopro
          v-if="isMounted"
          title="Urssaf - Au service de notre protection sociale"
          logo="logo-urssaf"
          :statut="acossInfo.statut"
          :statutLibelle="statutLibelle(acossInfo.statut)"
          :dateRattachement="acossInfo.dateRattachement"
          :isCompteRattache="acossInfo.isRattache"
          infoRattache="Vous allez être redirigé vers urssaf.fr pour finaliser le rattachement. Cette opération aura lieu une seule fois."
          infoInscript="Cliquez ici pour créer votre compte urssaf.fr, il sera rattaché automatiquement."
          nature="urssaf.fr"
          :handleChangeInfo="handleChangeCoproInfo"
          rattacherBtnId="rat-02"
          sinscrireBtnId="rat-03"
          derattacherBtnId="rat-04"
        />
        <BlocCopro
          v-if="isMounted"
          title="impots.douv.fr"
          logo="logo-DGFiP"
          :id="dgfipInfo.id"
          :statut="dgfipInfo.statut"
          :statutLibelle="statutLibelle(dgfipInfo.statut)"
          :dateRattachement="dgfipInfo.dateRattachement"
          :isCompteRattache="dgfipInfo.isRattache"
          infoRattache="Vous allez être redirigé vers impots.gouv.fr pour finaliser le rattachement. Cette opération aura lieu une seule fois."
          infoInscript="Cliquez ici pour créer votre compte impots.gouv.fr, il sera rattaché automatiquement."
          nature="impots.gouv.fr"
          :handleChangeInfo="handleChangeCoproInfo"
          rattacherBtnId="rat-05"
          sinscrireBtnId="rat-06"
          derattacherBtnId="rat-07"
        />
        <BlocCopro
          v-if="isMounted"
          title="Douanes et droits indirects"
          logo="logo-douane"
          :statut="douaneInfo.statut"
          :statutLibelle="statutLibelle(douaneInfo.statut)"
          :dateRattachement="douaneInfo.dateRattachement"
          :isCompteRattache="douaneInfo.isRattache"
          infoRattache="Vous allez être redirigé vers douane.gouv.fr pour finaliser le rattachement. Cette opération aura lieu une seule fois."
          infoInscript="Cliquez ici pour créer votre compte douane.gouv.fr, il sera rattaché automatiquement."
          nature="douane.gouv.fr"
          :handleChangeInfo="handleChangeCoproInfo"
          rattacherBtnId="rat-08"
          sinscrireBtnId="rat-09"
          derattacherBtnId="rat-10"
        />
        <BlocCopro
          v-if="isMounted"
          title="net-entreprises.fr - GIP Modernisation des déclarations sociales"
          logo="logo-net-entreprise"
          :statut="netEntreInfo.statut"
          :statutLibelle="statutLibelle(netEntreInfo.statut)"
          :dateRattachement="netEntreInfo.dateRattachement"
          :isCompteRattache="netEntreInfo.isRattache"
          infoRattache="Vous allez être redirigé vers net-entreprises.fr pour finaliser le rattachement. Cette opération aura lieu une seule fois."
          infoInscript="Cliquez ici pour créer votre compte net-entreprises.fr, il sera rattaché automatiquement."
          nature="net-entreprises.fr"
          :handleChangeInfo="handleChangeCoproInfo"
          rattacherBtnId="rat-11"
          sinscrireBtnId="rat-12"
          derattacherBtnId="rat-13"
        />
      </div>
      <div class="bouton-terminer" v-if="!connexionCourante">
        <button
          id="rat-01"
          class="pr-btn fr-btn"
          @click="toggleFinishRattachement"
          title="Cliquer ici pour terminer"
        >
          <span class="ri-check-line rattach-action">Terminer</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BlocCopro from '../components/BlocCopro';
import { mapGetters, mapActions } from 'vuex';
import SkipLinkMixin from '../mixins/skip-link-mixin';

import {
  URSSAF,
  NET,
  DGFIP,
  DOUANE,
  ENCOURS,
  NONRATTACHE,
  RATTACHE,
  DEMANDE_COMPTE_DGDDI_TYPE_VIDE,
  DEMANDE_COMPTE_PROCONNECT,
  EVENT_BUS_FERMER_MODAL
} from '../constantes';
import store from '../store';
import { updateData } from '../utils/navigationUtils';
import { habilitationService } from '../services/HabilitationService';

export default {
  name: 'rattachement',
  components: {
    BlocCopro
  },
  mixins: [SkipLinkMixin],
  data() {
    return {
      rattachementList: [],
      isMounted: false,
      rattachement_date_dgddi: undefined,
      rattachement_date_net_e: undefined,
      rattachement_date_dgfip: undefined,
      rattachement_date_urssaf: undefined,
      isPlaying: false,
      dgfipInfo: {
        isRattache: false,
        dateRattachement: null,
        statut: NONRATTACHE.statut
      },
      acossInfo: {
        isRattache: false,
        dateRattachement: null,
        statut: NONRATTACHE.statut
      },
      douaneInfo: {
        isRattache: false,
        dateRattachement: null,
        statut: NONRATTACHE.statut
      },
      netEntreInfo: {
        isRattache: false,
        dateRattachement: null,
        statut: NONRATTACHE.statut
      },
      componentPath: this.$route.path,
      coproMap: new Map()
    };
  },
  computed: {
    pageParamRattachementCopro: (_this = this) =>
      _this.$route.query['id-copro'],
    pageParamDerattachementCopro: (_this = this) =>
      _this.$route.query['derattacher-id-copro'],
    user() {
      return {
        idPCR: this.idPCR
      };
    },
    ...mapGetters(['idPCR', 'connexionCourante', 'siren'])
  },
  methods: {
    redictionYT() {
      window.open(process.env.VUE_APP_URL_VIDEO_RATTACHEMENT);
    },
    loadRattachement() {
      return this.$rattachementService
        .getRattachementList()
        .then(async (result) => {
          this.rattachementList = result.data;

          if (this.rattachementList && this.rattachementList.length != 0) {
            await this.$auth.getUserInfo().then((result) => {
              this.rattachement_date_dgddi = result.data.rattachement_date_dgddi
                ? new Date(
                    new Number(result.data.rattachement_date_dgddi)
                  ).toLocaleDateString('fr-FR')
                : undefined;
              this.rattachement_date_net_e = result.data.rattachement_date_net_e
                ? new Date(
                    new Number(result.data.rattachement_date_net_e)
                  ).toLocaleDateString('fr-FR')
                : undefined;
              this.rattachement_date_urssaf = result.data
                .rattachement_date_urssaf
                ? new Date(
                    new Number(result.data.rattachement_date_urssaf)
                  ).toLocaleDateString('fr-FR')
                : undefined;
              this.rattachement_date_dgfip = result.data.rattachement_date_dgfip
                ? new Date(
                    new Number(result.data.rattachement_date_dgfip)
                  ).toLocaleDateString('fr-FR')
                : undefined;
            });
          }

          let comptes = {};
          result.data.forEach((element) => {
            let info = {
              id: element.idp.id,
              isRattache: element.idp ? true : false,
              dateRattachement: undefined,
              statut: 'rattache'
            };

            switch (element.idp.name) {
              case this.getIdCoproInclude(element.idp.name, URSSAF.idCopro):
                this.acossInfo = info;
                comptes = {
                  ...comptes,
                  urssaf: true
                };
                this.acossInfo.dateRattachement = this.rattachement_date_urssaf;
                this.coproMap.set(URSSAF.idCopro, URSSAF.nature);
                break;
              case this.getIdCoproInclude(element.idp.name, NET.idCopro):
                this.netEntreInfo = info;
                comptes = {
                  ...comptes,
                  net_e: true
                };
                this.netEntreInfo.dateRattachement = this.rattachement_date_net_e;
                this.coproMap.set(NET.idCopro, NET.nature);
                break;
              case this.getIdCoproInclude(element.idp.name, DGFIP.idCopro):
                this.dgfipInfo = info;
                comptes = {
                  ...comptes,
                  dgfip: true
                };
                this.dgfipInfo.dateRattachement = this.rattachement_date_dgfip;
                this.coproMap.set(DGFIP.idCopro, DGFIP.nature);
                break;
              case this.getIdCoproInclude(element.idp.name, DOUANE.idCopro):
                this.douaneInfo = info;
                comptes = {
                  ...comptes,
                  dgddi: true
                };
                this.douaneInfo.dateRattachement = this.rattachement_date_dgddi;
                this.coproMap.set(DOUANE.idCopro, DOUANE.nature);
                break;
              default:
                break;
            }
          });

          store.dispatch('setComptesRattachees', comptes);
          return comptes;
        });
    },
    verificationDemandeDeCompteEnCoursURSSAF() {
      this.$comptesService
        .getListeDemandeParUtilisateur('A_TRAITER')
        .then((res) => {
          const demandesEnCours = res.data;
          const demandeUrssaf = demandesEnCours.filter(
            (demande) => demande.coproprietaire === 'URSSAF'
          );
          if (
            demandesEnCours &&
            demandesEnCours.length !== 0 &&
            demandeUrssaf.length !== 0
          ) {
            this.acossInfo.statut = 'enCours';
            this.acossInfo.isRattache = false;
          }
        });
    },
    getIdCoproInclude(name, idCopro) {
      if (name.includes(idCopro)) {
        return name;
      }
    },
    statutLibelle(statut) {
      if (statut === ENCOURS.statut) {
        return ENCOURS.libelle;
      }
      if (statut === RATTACHE.statut) {
        return RATTACHE.libelle;
      }
      return NONRATTACHE.libelle;
    },
    handleChangeCoproInfo(nature, field, value) {
      switch (nature) {
        case URSSAF.nature:
          this.acossInfo[field] = value;
          break;
        case NET.nature:
          this.netEntreInfo[field] = value;
          break;
        case DGFIP.nature:
          this.dgfipInfo[field] = value;
          break;
        case DOUANE.nature:
          this.douaneInfo[field] = value;
          break;
        default:
          break;
      }
    },
    async toggleFinishRattachement() {
      this.setConnexionCourante(true);
      await updateData().then(() => {
        this.fetchCodeAffichages(store.state.compte.siren);
      });

      const hasValidHabilitation = await this.checkHabilitations();

      if (this.rattachementList.length !== 0 && hasValidHabilitation) {
        if (store.state.compte.isMonoSiren === true) {
          this.$router.push({ name: 'Tableau de bord' }).catch((e) => {
            console.error(e);
          });
        } else {
          this.$router
            .push({ name: "Sélection de l'entreprise" })
            .catch((e) => {
              console.error(e);
            });
        }
      } else {
        this.$router.push({ name: 'Service non accessible' }).catch((e) => {
          console.error(e);
        });
      }
    },

    async checkHabilitations() {
      const habilitationRequest = {
        siren: store.state.compte.siren,
        subModificateur: ''
      };

      const response = await habilitationService.getHabilitationList(
        habilitationRequest,
        this.setListHabilitation,
        false
      );

      if (!response || response.length === 0) {
        return false;
      }

      return true;
    },

    navigateToFAQ(event) {
      event.preventDefault();
      this.$router.push({
        name: 'Foire aux questions',
        query: {
          q: 'que-faire-sur-portailpro-avec-un-rattachement-net-entreprises'
        }
      });
    },

    checkFirstConnexion() {
      if (
        !this.connexionCourante &&
        this.rattachementList &&
        this.rattachementList.length > 0
      ) {
        const compte = this.coproMap.get(
          this.rattachementList[this.rattachementList.length - 1].idp.name
        );
        let html = '';

        if (compte === 'net-entreprises.fr') {
          html = `<p class="fs-875">Votre compte Portailpro.gouv est désormais rattaché à votre compte net-entreprises.fr. Ce dernier permet de déclarer la DSN depuis le tableau de bord si vous disposez également d’un rattachement URSSAF avec les droits nécessaires. Veuillez consulter la <a href="#" id="faq-link">FAQ</a> pour plus d’informations.</p>`;
          this.setContent({ html, action: 'info' });
          this.showPopup();

          this.$nextTick(() => {
            const faqLink = document.getElementById('faq-link');
            if (faqLink) {
              faqLink.addEventListener('click', this.navigateToFAQ);
            }
          });
        } else if (
          parseInt(process.env.VUE_APP_NB_MAX_COPRO) !==
          this.rattachementList.length
        ) {
          html = `<p class="fs-875">Votre compte <b>Portailpro.gouv</b> est désormais rattaché à votre compte <b>${compte}</b>.
        <br/> Souhaitez-vous rattacher votre compte à un autre compte tiers ?</p>`;
          this.setContent({ html, action: 'confirm-plus-tard' });
          this.showPopup();
          this.emitter.once('popup', async (payload) => {
            if (payload !== 'oui') {
              await updateData().then(() => {
                this.fetchCodeAffichages(store.state.compte.siren);
              });
              store.dispatch('setConnexionCourante', true);
              this.$router.push({ name: 'Tableau de bord' }).catch(() => {});
            }
          });
        } else {
          // Message générique si tous les comptes possibles sont déjà rattachés
          html = `<p class="fs-875">Votre compte <b>Portailpro.gouv</b> est désormais rattaché à votre compte <b>${compte}</b>.</p>`;
          this.setContent({ html, action: 'info' });
          this.showPopup();
        }
      }
    },

    async checkRattachementDerattachementCopro() {
      if (this.pageParamRattachementCopro) {
        let infoRattachementCopro = localStorage.getItem(
          'infoRattachementCopro'
        );

        if (infoRattachementCopro) {
          infoRattachementCopro = JSON.parse(infoRattachementCopro);

          if (infoRattachementCopro.errorRattachement === true) {
            const html = `<p class="fs-875">${infoRattachementCopro.message}</p>`;
            this.setContent({ html, action: 'info' });
            this.showPopup();
          }

          localStorage.removeItem('infoRattachementCopro');
          await updateData();
        }
        this.$router.replace({ 'id-copro': null });
      }

      if (this.pageParamDerattachementCopro) {
        let infoDerattachementCopro = localStorage.getItem(
          'infoDerattachementCopro'
        );

        if (infoDerattachementCopro) {
          infoDerattachementCopro = JSON.parse(infoDerattachementCopro);

          if (
            infoDerattachementCopro.errorDerattachement === true ||
            infoDerattachementCopro.successDerattachement === true
          ) {
            const html = `<p class="fs-875">${infoDerattachementCopro.message}</p>`;
            this.setContent({ html, action: 'info' });
            this.showPopup();
          }

          localStorage.removeItem('infoDerattachementCopro');
          await updateData();
        }
        this.$router.replace({ 'derattacher-id-copro': null });
      }
    },

    showCreationDgddiPopup() {
      const html = `<p class="fs-875"> Votre compte <b>douane.gouv.fr</b> a bien été créé et rattaché. <br>Voulez-vous activer vos habilitations pour bénéficier des services douaniers ?</p>`;

      this.setContent({ html, action: 'confirm' });
      this.showPopup();
      this.emitter.once('popup', (payload) => {
        if (payload === 'oui') {
          this.$router.push({ name: 'Gestion des habilitations' });
        }
      });
    },

    ...mapActions([
      'setConnexionCourante',
      'showPopup',
      'setContent',
      'fetchCodeAffichages'
    ])
  },
  async mounted() {
    await this.loadRattachement();
    await this.checkRattachementDerattachementCopro();
    await this.verificationDemandeDeCompteEnCoursURSSAF();
    if (this.$route.query?.creationCT) {
      if (
        this.$route.query?.creationCT === DOUANE.codeCopro &&
        this.$route.query?.type === DEMANDE_COMPTE_DGDDI_TYPE_VIDE &&
        !this.$route.query?.html &&
        this.$route.query?.html.length === 0
      ) {
        this.showCreationDgddiPopup();
      } else {
        const html = this.$route.query?.html;
        let action = this.$route.query?.action;
        if (!action) {
          action = 'info';
        }
        this.setContent({ html, action: action });
        this.showPopup();
      }

      const ctOrigine = localStorage.getItem('ctOrigine');

      if (!ctOrigine || ctOrigine !== DEMANDE_COMPTE_PROCONNECT) {
        await updateData();
      }
      localStorage.removeItem('ctOrigine');
      this.$router.replace({});
    } else {
      await this.checkFirstConnexion();
    }
    this.isMounted = true;
    this.emitter.on(EVENT_BUS_FERMER_MODAL, (id) => {
      document.getElementById(id).focus();
    });
  }
};
</script>

<style lang="scss" scoped>
.confirm-rattach {
  margin: 2rem;
  text-align: center;
}
.ri-checkbox-circle-fill {
  color: #ffc29e;
  font-size: 57px;
  background-color: #ffffff;
  position: relative;
  top: 17px;
}
h1 {
  font-size: 2.5rem;
  margin-bottom: 35px;
}
h2 {
  color: $base-color;
}
.bloc-copro {
  background-color: #f9f8f6;
  height: auto;
  padding: 36px 0;
}
.each-row-copro {
  border-bottom: solid #f9f8f6 23px;
  width: max-content;
  margin: auto;
}
.bouton-terminer {
  justify-content: center;
  display: flex;
}
.pr-btn {
  background: $base-color;
  color: white;
  font-size: 14px;
  border: 0;
  height: 49px;
  width: 160px;
}
.ri-check-line {
  font-size: 17px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.ri-check-line::before {
  font-weight: bold;
  position: relative;
  right: 7px;
  font-size: 25px;
}
.bloc-rattachement {
  > div {
    margin-bottom: 15px;
  }
}
.confirm-rattach-message {
  font-weight: bold !important;
  font-size: 2rem;
}
.rattachement-bienvenue-img {
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.donne-avis {
  background-color: white;
  width: 12%;
}
.titleRattachement {
  text-align: left;
}
.right-bloc-video {
  display: inline-block;
}
.video {
  display: table;
  width: 70%;
  position: relative;
}
.video-bloc {
  width: 100%;
  height: auto;
  cursor: pointer;
}
.playpause {
  background: url('../assets/video-play.svg');
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  left: -2%;
  top: -5%;
}

.playpause:hover {
  background: url('../assets/video-play-hover.svg');
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  left: -2%;
  top: -5%;
}

.playpause img {
  top: 9px;
  position: relative;
}

@media only screen and (max-width: 760px) {
  .bloc-rattachement {
    display: table;
  }
  .right-bloc-video {
    width: 90%;
    display: table-header-group;
  }
  .each-row-copro {
    display: block;
    width: 100%;
  }

  .titre-container {
    display: contents;
  }

  .etat-copro {
    margin: 1rem;
    width: 90%;
  }

  .text-message {
    width: 90%;
  }

  .donne-avis {
    width: 30%;
  }
}
</style>
