<template>
  <div :class="rattachementClass">
    <div :class="{ 'container-logo-douane': nature === 'douane.gouv.fr' }">
      <img
        class="logo-urssaf"
        v-if="logo == 'logo-urssaf'"
        src="../assets/img/logo-urssaf.svg"
        alt="logo de l'URSSAF"
      />
      <img
        class="logo-DGFiP"
        v-if="logo == 'logo-DGFiP'"
        src="../assets/img/logo-impots.svg"
        alt="logo de la DGFIP"
      />
      <img
        class="logo-douane"
        v-if="logo == 'logo-douane'"
        src="../assets/img/logo-douane.svg"
        alt="logo de la DGDDI"
      />
      <img
        class="logo-net-entreprise"
        v-if="logo == 'logo-net-entreprise'"
        src="../assets/img/net-entreprises_logo.svg"
        alt="logo de la Net-ENTREPRISE"
      />
      <i v-else :class="logo"></i>
      <h2 class="sr-only">{{ title }}</h2>
      <span class="fs-875 fs-b" v-if="nature === 'douane.gouv.fr'">{{
        nature
      }}</span>
    </div>
    <button
      :id="derattacherBtnId"
      type="button"
      class="ri-delete-bin-line"
      v-if="isCompteRattache"
      @click="derattacher(id, $event)"
      :title="'Cliquez ici pour supprimer ce rattachement ' + title"
      :aria-label="'Cliquez ici pour supprimer le rattachement de ' + title"
    >
      <span class="hide-element">Détacher son compte {{ title }}</span>
    </button>
    <div class="info-non-rattach" v-if="statut === 'nonRattachee'">
      <button
        :id="rattacherBtnId"
        class="fr-btn pr-btn rattacher"
        @click="
          rattacher();
          tagCopro(nature);
        "
        :title="infoRattache"
        aria-label="Rattacher mon compte"
      >
        <span aria-hidden="true">Rattacher mon compte</span>
        <span class="sr-only">Rattacher mon compte {{ title }}</span>
      </button>
      <button
        :id="sinscrireBtnId"
        class="inscrireText fs-750"
        @click="sinscrire()"
        :title="infoInscript"
        aria-label="Pas encore de compte ?"
      >
        <span class="soulignement">Pas encore de compte ?</span>
      </button>
    </div>
    <div class="info-rattach" v-else>
      <div v-if="statut === 'rattache'">
        <i class="ri-checkbox-circle-line" />
        <span class="etat" aria-hidden="true">{{ statutLibelle }}</span>
        <span class="sr-only">{{ statutLibelle }} {{ title }}</span>
        <span v-if="dateRattachement" class="date-rattach"
          >depuis le {{ dateRattachement }}</span
        >
      </div>
      <div v-if="statut === 'enCours'">
        <i class="ri-time-line"></i>
        <span class="etat">{{ statutLibelle }}</span>
      </div>
    </div>
    <CreationCompteRgTiModal
      v-show="isRgTiShowModal"
      @close="closeAll()"
      @closeAndOpen="rgTiCloseModal()"
      v-if="isCoproUrssaf"
    />
    <CertificationProconnectSirenInconnuModal
      v-if="showModalCertifSirenInconnu"
      @close="closeModalSirenInconnu()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CreationCompteRgTiModal from '../components/RegimeGeneralTravailleurIndependantModal';
import {
  URSSAF,
  NET,
  DGFIP,
  DOUANE,
  RATTACHE,
  NONRATTACHE
} from '../constantes';
import { dateFormat } from '../filters';
import CertificationProconnectSirenInconnuModal from '../components/CertificationProconnectSirenInconnuModal';
import store from '../store';
import {
  getDerattachementMessage,
  getHabilitationSirenErrorMessage,
  onGetSirenList
} from '../utils/methodsUtils';
import { updateData } from '../utils/navigationUtils';
import { normalizeString, sendTag } from '../utils/methodsUtils';
import { EVENT_BUS_FERMER_MODAL } from '../constantes';

export default {
  name: 'blocCopro',
  components: {
    CreationCompteRgTiModal,
    CertificationProconnectSirenInconnuModal
  },
  data() {
    return {
      isRgTiShowModal: false,
      isCoproUrssaf: this.nature === URSSAF.nature,
      compteUrssafExist: true,
      showModalCertifSirenInconnu: false
    };
  },
  props: {
    id: String,
    logo: String,
    title: String,
    statut: String,
    statutLibelle: String,
    dateRattachement: String,
    isCompteRattache: Boolean,
    infoRattache: String,
    infoInscript: String,
    nature: String,
    handleChangeInfo: Function,
    rattacherBtnId: String,
    sinscrireBtnId: String,
    derattacherBtnId: String
  },
  computed: {
    rattachementClass() {
      if (this.statut === NONRATTACHE.statut) {
        return 'etat-copro';
      } else {
        return 'etat-copro etat-copro-rattache';
      }
    },
    ...mapGetters([
      'connexionCourante',
      'idPCR',
      'email',
      'compteTechniqueDouane',
      'compteTechniqueDgfip',
      'comptesRattachees'
    ])
  },
  mounted() {
    if (
      localStorage.getItem('nature') &&
      this.nature == localStorage.getItem('nature')
    ) {
      const nature = localStorage.getItem('nature');
      const idCopro = this.getIdCoproByNature(nature);
      const listCompte = Object.keys(this.comptesRattachees);
      const compteRattache = listCompte.find((copro) => copro == idCopro);
      if (compteRattache) {
        this.rattacherShowPopup(nature);
      }
      localStorage.removeItem('nature');
    }
  },
  methods: {
    getIdCoproByNature(nature) {
      switch (nature) {
        case URSSAF.nature:
          return URSSAF.idCopro;
        case NET.nature:
          return NET.idCopro;
        case DGFIP.nature:
          return DGFIP.idCopro;
        case DOUANE.nature:
          return DOUANE.idCopro;
      }
    },
    closeAll() {
      this.isRgTiShowModal = false;
      this.showModalCertifSirenInconnu = false;
    },
    rgTiShowModal() {
      this.isRgTiShowModal = true;
      this.ccAmCloseModal();
    },
    rgTiCloseModal() {
      this.isRgTiShowModal = false;
      this.ccAmShowModal();
    },
    showModalSirenInconnu() {
      this.showModalCertifSirenInconnu = true;
    },
    closeModalSirenInconnu() {
      this.showModalCertifSirenInconnu = false;
    },

    derattacher() {
      let idCopro = '';
      let html = `<p class="fs-875">Souhaitez-vous supprimer le rattachement avec votre compte <b>${this.nature}</b> ?</p>`;
      switch (this.nature) {
        case NET.nature:
          idCopro = NET.idCopro;
          this.derattachement(html, idCopro);
          break;
        case URSSAF.nature:
          idCopro = URSSAF.idCopro;
          this.derattachement(html, idCopro);
          break;
        case DGFIP.nature:
          idCopro = DGFIP.idCopro;
          if (this.compteTechniqueDgfip === true) {
            const sirenRequest = {
              page: 1,
              size: 10,
              filter: 'siren'
            };
            const rattachements = {
              dgfip: true
            };
            this.$habilitationService
              .getSirenListByCopro(sirenRequest, rattachements)
              .then((response) => {
                if (this.hasDgfipHabilitations(response)) {
                  html = `<p class="fs-875">Vous pouvez supprimer les habilitations liées à votre compte depuis le lien suivant : <a href=${process.env.VUE_APP_URL_DGFIP_NO_AUTH}><b>${process.env.VUE_APP_URL_DGFIP_NO_AUTH}</b><a> . Vous pourrez revenir sur cet écran pour dérratacher votre compte.</b></p>`;
                  this.setContent({ html, action: 'info' });
                  this.showPopup();
                } else {
                  html = `<p class="fs-875">Souhaitez-vous supprimer le rattachement avec votre compte <b>impots.gouv.fr</b> ?</p>`;
                  this.setContent({ html, action: 'confirm' });
                  this.showPopup();

                  this.emitter.once('popup', (payload) => {
                    if (payload === 'oui') {
                      setTimeout(() => {
                        html = `<p class="fs-875">Attention, votre compte <b>impots.gouv.fr</b> que vous souhaitez dérattacher sera définitivement perdu. <br>Vous ne pourrez pas réutiliser l'adresse courriel associée à ce compte pour la création d'un nouvel espace professionnel sur <b>impots.gouv.fr</b>. <br>Voulez-vous vraiment supprimer le rattachement avec ce compte ?</p>`;
                        this.derattachement(html, idCopro);
                      }, 200); // Le timeout permet pour l'EventBus de pouvoir gerer l'affichage des deux popup correctement
                    }
                  });
                }
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            this.derattachement(html, idCopro);
          }
          break;
        case DOUANE.nature:
          idCopro = DOUANE.idCopro;

          if (this.compteTechniqueDouane === true) {
            html = `<p class="fs-875"> Afin de continuer à bénéficier de vos services douaniers,
          nous vous invitons à récupérer vos identifiants de connexion depuis <b>douane.gouv.fr</b>
           (<a href=${process.env.VUE_APP_URL_DOUANE_NOTICE} target="noopener noreferrer" title="site ${process.env.VUE_APP_URL_DOUANE_NOTICE} - Nouvel onglet"><u>notice</u><a>).<br>
           Souhaitez-vous continuer la suppression du rattachement de votre compte <b>Portailpro.gouv</b> ?</p>`;
          }
          this.derattachement(html, idCopro);
          break;
      }
    },

    hasDgfipHabilitations(response) {
      if (response.data && response.data.sirens.length > 0) {
        const sirens = response.data.sirens;
        const sirensWithHab = sirens.filter(
          (sir) => sir.habilitations && sir.habilitations.length > 0
        );
        if (sirensWithHab && sirensWithHab.length > 0) {
          return true;
        }
        return false;
      }
      return false;
    },

    derattachement(html, idCopro) {
      this.setContent({ html, action: 'confirm' });
      this.showPopup();
      this.emitter.once('popup', (payload) => {
        if (payload === 'oui') {
          this.$rattachementService
            .deleteRattachement(idCopro)
            .then(async () => {
              await this.successDerattachement(idCopro);
            })
            .catch(async (error) => {
              const derattachementError = getDerattachementMessage(
                idCopro,
                error?.response?.data?.code
              );

              if (!derattachementError || !derattachementError.message) {
                html = `<p class="fs-875">Votre demande n'a pas pu aboutir. Veuillez la renouveler ultérieurement.</p>`;
                this.setContent({ html, action: 'info' });
                this.showPopup();
              } else {
                if (derattachementError.finaliseDerattachement === true) {
                  await this.successDerattachement(idCopro);
                } else {
                  html = `<p class="fs-875">${derattachementError.message}</p>`;
                  this.setContent({ html, action: 'info' });
                  this.showPopup();
                }
              }
            });
        }
        if (payload === 'annuler') {
          this.emitter.emit(EVENT_BUS_FERMER_MODAL, this.derattacherBtnId);
        }
        if (payload === 'hide') {
          this.emitter.emit(EVENT_BUS_FERMER_MODAL, this.derattacherBtnId);
        }
      });
    },

    async successDerattachement(idCopro) {
      this.handleChangeInfo(this.nature, 'statut', 'nonRattachee');
      this.handleChangeInfo(this.nature, 'isRattache', false);
      this.handleChangeInfo(this.nature, 'dateRattachement', null);

      if (this.compteTechniqueDouane === true && DOUANE.idCopro === idCopro) {
        store.dispatch('setCompteTechniqueDouane', false);
      }

      let _this = this;
      await this.$parent.loadRattachement().then(async (res) => {
        _this.setComptesRattachees(res);
        this.showDerattacherInfo(res);
        this.emitter.once('popup', (payload) => {
          if (payload === 'ok') {
            this.emitter.emit(EVENT_BUS_FERMER_MODAL, this.rattacherBtnId);
          }
        });
        await updateData().then(() => {
          this.fetchCodeAffichages(store.state.compte.siren);
        });
      });
    },

    showDerattacherInfo(result) {
      let html = `<p class="fs-875">Votre compte <b>${this.nature}</b> n’est plus rattaché à votre compte <b>Portailpro.gouv</b>.</p>`;
      if (Object.keys(result).length == 0) {
        html = `<p class="fs-875">Pour activer les fonctionnalités de votre compte <b>Portailpro.gouv</b> , vous devez le rattacher à un compte tiers au minimum.</p>`;
      }
      this.setContent({ html, action: 'info' });
      this.showPopup();
    },

    rattacher() {
      this.rattachementCompteIAM(this.nature);
    },

    rattacherUrssafPopup() {
      if (!this.compteUrssafExist) {
        this.rattacherShowPopup(URSSAF.nature);
      } else {
        const html =
          '<p class="fs-875 text-center">Si vous êtes un travailleur indépendant artisan/commerçant ou de profession libérale,' +
          '<br> soyez vigilant au moment de saisir les identifiants de votre compte <b>urssaf.fr</b> à rattacher.' +
          '<br>En rattachant votre compte de travailleur indépendant, vous récupérez toutes vos habilitations, ' +
          'y compris celles relatives à votre compte employeur si vous en avez un.</p>';
        this.setContent({ html, action: 'info' });
        this.showPopup();
        let _this = this;
        this.emitter.once('popup', (payload) => {
          if (payload === 'ok') {
            this.$rattachementService
              .rattacher(URSSAF.idCopro, URSSAF.nature)
              .then(() => {
                this.rattacherShowPopup(URSSAF.nature);
                this.$parent.loadRattachement().then((res) => {
                  _this.setComptesRattachees(res);
                });
                this.handleChangeInfoOk();
              });
          }
        });
      }
    },

    rattacherShowPopup(nature) {
      let html = `<p class="fs-875" style="text-align: center;">Votre compte <b>Portailpro.gouv</b> est désormais rattaché à votre compte <b>${nature}</b>.</p>`;

      /*
      if (NET.nature === nature) {
        html = `<p class="fs-875" style="text-align: center;">
       Votre compte net-entreprises a bien été créé et rattaché à votre compte <b>Portailpro.gouv</b>.`;
      }
      */

      let action = 'info';

      this.$rattachementService.getRattachementList().then((result) => {
        if (this.connexionCourante) {
          this.setContent({ html, action: 'info' });
          this.showPopup();
        } else {
          if (
            parseInt(process.env.VUE_APP_NB_MAX_COPRO) !== result.data.length
          ) {
            html = html.concat(
              `<p class="fs-875">Souhaitez-vous rattacher votre compte à un autre compte tiers ?</p>`
            );
            action = 'confirm-plus-tard';
            this.setContent({ html, action: action });
            this.showPopup();
            this.emitter.once('popup', async (payload) => {
              if (payload !== 'oui') {
                await updateData();
                store.dispatch('setConnexionCourante', true);
                this.$router.push({ name: 'Tableau de bord' }).catch(() => {});
              }
            });
          } else {
            this.setContent({ html, action: action });
            this.showPopup();
          }
        }
        this.emitter.once('popup', (payload) => {
          if (payload === 'ok') {
            this.emitter.emit(EVENT_BUS_FERMER_MODAL, this.derattacherBtnId);
          }
          if (payload === 'hide') {
            this.emitter.emit(EVENT_BUS_FERMER_MODAL, this.derattacherBtnId);
          }
        });
      });
    },

    rattachementCompteIAM(nature) {
      let idCopro = '';
      switch (nature) {
        case URSSAF.nature:
          idCopro = URSSAF.idCopro;
          break;
        case NET.nature:
          idCopro = NET.idCopro;
          break;
        case DGFIP.nature:
          idCopro = DGFIP.idCopro;
          break;
        case DOUANE.nature:
          idCopro = DOUANE.idCopro;
          break;
        default:
          idCopro = 'metaidp';
          break;
      }
      let html = `<p class="fs-875">Vous allez être redirigé vers <b>${this.nature}</b> pour vous authentifier et finaliser le rattachement.`;
      this.setContent({ html, action: 'none' });
      this.showPopup();
      setTimeout(() => {
        this.hidePopup();
        // TODO vérifier cas erreur (catch) en test
        this.$rattachementService
          .rattacher(idCopro, nature)
          .then(() => {
            this.handleChangeInfoOk();
            this.rattacherShowPopup(nature);
            this.$parent.loadRattachement().then(async (res) => {
              this.setComptesRattachees(res);
              await updateData().then(() => {
                this.fetchCodeAffichages(store.state.compte.siren);
              });
            });
          })
          .catch(() => {
            const html =
              "<p class='fs-875 text-center'>Le rattachement n'a pas pu être finalisé. Veuillez renouveler votre demande ultérieurement.</p>";
            this.setContent({ html, action: 'info' });
            this.showPopup();
          });
      }, parseInt(process.env.VUE_APP_POPUP_RATTACHEMENT_MS));
    },

    sinscrire() {
      const html = `<p class="fs-875 text-center">Souhaitez-vous continuer la création de votre compte <b>${this.nature}</b> ?</p>`;
      switch (this.nature) {
        case URSSAF.nature:
          if (
            localStorage.getItem('creationCompteRedirectionUrssaf') ===
              'true' &&
            process.env.VUE_APP_SUSPENSION_CREATION_COMPTE_URSSAF === 'true'
          ) {
            window.location.href = process.env.VUE_APP_URL_URSSAF_NO_AUTH;
          } else {
            window.location.href = '/ms/creation-compte-technique-urssaf';
          }
          break;
        case NET.nature:
          this.setContent({ html, action: 'confirm' });
          this.showPopup();
          this.emitter.once('popup', (payload) => {
            if (payload === 'oui') {
              if (
                localStorage.getItem(
                  'creationCompteRedirectionNetEntreprise'
                ) === 'true' &&
                process.env.VUE_APP_SUSPENSION_CREATION_COMPTE_NETENTREPRISE ===
                  'true'
              ) {
                window.location.href =
                  process.env.VUE_APP_URL_COMPTE_TECHNIQUE_NET;
              } else {
                window.location.href = process.env.VUE_APP_GIP_RATTACHEMENT;
              }
            }
          });
          break;
        case DGFIP.nature:
          this.$router.push({ path: 'creation-compte-technique-dgfip' });
          break;
        case DOUANE.nature:
          window.location.href = '/ms/creation-compte-technique-dgddi';
          break;
      }
    },

    handleChangeInfoOk() {
      this.handleChangeInfo(this.nature, 'statut', RATTACHE.statut);
      this.handleChangeInfo(this.nature, 'isRattache', true);
      this.handleChangeInfo(
        this.nature,
        'dateRattachement',
        dateFormat(this.dateRattachement)
      );
    },
    getSirenList() {
      const sirenRequest = {
        page: 1,
        size: 10,
        filter: 'siren'
      };
      this.$habilitationService
        .getSirenList(sirenRequest)
        .then((result) => {
          onGetSirenList(result.data.sirens, true);
        })
        .catch((err) => {
          const errorMessage = getHabilitationSirenErrorMessage(
            err?.response?.data
          );
          if (errorMessage) {
            const html = `<p class="fs-875">${errorMessage}</p>`;
            this.setContent({ html, action: 'info' });
            this.showPopup();
          }
        });
    },

    ...mapActions([
      'showPopup',
      'hidePopup',
      'setContent',
      'setComptesRattachees',
      'fetchCodeAffichages'
    ]),
    tagCopro(tagName) {
      sendTag(
        'rattacher_mon_compte',
        'gestion_des_rattachements',
        'rattacher_mon_compte',
        normalizeString(tagName)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.ri-checkbox-circle-line {
  color: #16a757;
  font-size: 1.4rem;
  top: 0.2rem;
  position: relative;
}
.ri-time-line {
  color: $base-color;
  font-size: 1.4rem;
  top: 0.2rem;
  position: relative;
}
.etat {
  left: 0.5rem;
  position: relative;
  font-size: 16px;
  font-weight: bold;
}
.etat-copro {
  display: flex;
  flex-direction: column;
  width: 250px;
  padding: 20px 10px;
  height: 235px;
  text-align: center;
  background-color: #fff;
  border: solid 1px #dddddd;
  .info-rattach {
    position: absolute;
    transform: translateY(7rem);
    width: 14rem;
  }
}
.etat-copro:hover {
  box-shadow: #dddddd 0px 6px 30px 0px !important;
}
.etat-copro-rattache {
  box-shadow: 0 6px 30px 0 #ddd;
  border: solid 0px #dddddd;
}
.date-rattach {
  display: flex;
  font-size: 12px;
  color: #625a5a;
  font-style: italic;
  margin-left: 67px;
}
.logo-DGFiP {
  width: 9.5rem;
  margin: 30px auto 23px auto;
}
.logo-urssaf {
  width: 9rem;
  margin: 17px auto 25px auto;
}
.logo-douane {
  width: 41px;
  margin-right: 15px;
}
.logo-net-entreprise {
  width: 12.5rem;
  margin-top: 30px;
}
.inscrireText {
  height: 24px;
  margin: 6px auto 0 auto;
  padding: 6px 0 4px 0;
  color: $base-color;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.inscrireText:hover {
  background-color: transparent;
}

.ri-delete-bin-line {
  position: absolute;
  transform: translateX(12.5rem) translateY(-0.7rem);
  color: $base-color;
  border: solid 1px $base-color;
  background-color: #fff;
  width: 1.813rem;
}
.ri-delete-bin-line:focus {
  outline: solid 2px #2a7ffe;
}
.info-non-rattach {
  position: absolute;
  transform: translateY(6.5rem);
  width: 230px;
  > button:nth-child(1) {
    width: 222px;
    height: 35px;
    margin-top: 10px;
  }
}
.pr-btn {
  background: $base-color;
  color: white;
  font-size: 14px;
  border: 0;
  height: 49px;
  width: 160px;
}
.pr-btn:hover {
  background-color: #1212ff;
}
.container-logo-douane {
  color: rgb(19, 19, 100);
  margin-top: 1rem;
}
.info-ratt {
  color: #fff;
  size: 0.85rem;
}
.container-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 760px) {
  .info-non-rattach {
    width: 100%;
    padding-right: 20%;
  }

  .etat-copro .info-rattach {
    width: 80%;
  }

  .etat-copro:hover {
    transform: unset;
  }

  .ri-delete-bin-line {
    align-self: end;
    transform: translateX(0.1rem) translateY(-0.7rem);
  }

  .etat-copro:hover {
    transform: none !important;
  }
}
.hide-element {
  display: none;
}

.soulignement {
  border-bottom: solid 0.8px #000091;
}

.soulignement:hover {
  border-bottom-width: 2.2px;
}
</style>
