<template>
  <div class="modal-backdrop">
    <div
      class="modalRgTi"
      id="modalRgTi"
      ref="modal"
      role="dialog"
      aria-modal="true"
      aria-label="Travailleur indépendant"
      tabindex="-1"
    >
      <header class="modal-header">
        <slot class="header">
          <button
            type="button"
            class="btn-close"
            title="Cliquer ici pour fermer fenêtre travailleur indépendant régime général"
            @click="close"
            style="box-sizing: unset"
          >
            Fermer X
          </button>
        </slot>
      </header>
      <section class="modal-body">
        <slot name="body">
          <p class="fs-875">
            Souhaitez-vous accéder aux services Portailpro.gouv en tant <br />
            qu'<b>employeur</b> ou
            <b>autres (travailleur indépendant / tiers déclarant,...)</b> ?
          </p>
          <div>
            <button
              class="pr-btn mr-3 rattacher"
              @click="sinscrireRg()"
              title="Cliquer ici pour accéder aux services Portailpro.gouv en tant que employeur"
            >
              <span>Employeur</span>
            </button>
            <button
              class="pr-btn autre-btn rattacher"
              @click="sinscrireTi()"
              title="Cliquer ici pour accéder aux services Portailpro.gouv en tant que autres(travailleur indépendant / tiers déclarant...etc)"
            >
              <span>Autres</span>
            </button>
          </div>
        </slot>
      </section>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  name: 'CreationCompteRgTiModal',
  props: {
    isRgTiShowModal: Boolean
  },

  methods: {
    close() {
      this.$emit('close');
    },

    closeAndOpen() {
      this.$emit('closeAndOpen');
    },

    sinscrireRg() {
      this.closeAndOpen();
    },
    sinscrireTi() {
      this.close();
      const html =
        '<p class="fs-875">Vous allez être redirigé sur le site <b>urssaf.fr</b> afin de créer votre compte.</p>';
      this.setContent({ html, action: 'info' });
      this.showPopup();
      this.emitter.once('popup', (payload) => {
        if (payload === 'ok') {
          window.open(
            process.env.VUE_APP_TRAVAILLEUR_INDEPENDANT,
            'noopener noreferrer'
          );
        }
      });
    },
    ...mapActions(['showPopup', 'setContent']),
    focusOnModal(e) {
      if (this.isRgTiShowModal) {
        // add all the elements inside modal which you want to make focusable
        const focusableElements =
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
        const modal = document.querySelector('#modalRgTi'); // select the modal by it's id

        const firstFocusableElement = modal.querySelectorAll(
          focusableElements
        )[0]; // get first element to be focused inside modal
        const focusableContent = modal.querySelectorAll(focusableElements);
        const lastFocusableElement =
          focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

        let isTabPressed =
          e.key === 'Tab' ||
          e.keyCode === 9 ||
          e.key === 'Escape' ||
          e.keyCode === 27;

        if (!isTabPressed) {
          return;
        }

        let isInternalElt = false;
        for (
          let i = 0;
          i < modal.querySelectorAll(focusableElements).length;
          i++
        ) {
          if (
            document.activeElement ===
            modal.querySelectorAll(focusableElements)[i]
          ) {
            isInternalElt = true;
          }
        }
        if (e.key === 'Escape' || e.keyCode === 27) {
          this.close();
        } else {
          if (e.shiftKey) {
            // if shift key pressed for shift + tab combination
            if (
              !isInternalElt ||
              document.activeElement === firstFocusableElement
            ) {
              //is the active element part of the modal
              lastFocusableElement.focus();
              e.preventDefault();
            }
          } else {
            // if tab key is pressed
            if (
              !isInternalElt ||
              document.activeElement === lastFocusableElement
            ) {
              firstFocusableElement.focus();
              e.preventDefault();
            }
          }
        }
      }
    }
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.onKeyDownFocus);
  },
  mounted() {
    window.addEventListener('keydown', this.onKeyDownFocus);
  }
};
</script>
<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalRgTi {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1050;
  overflow: hidden;
  outline: 0;
  background-color: white;
}
.modal-header {
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
  border-bottom: unset;
}
.btn-close {
  border: none;
  width: 4rem;
  font-size: 12px;
  padding: 20px;
  cursor: pointer;
  font-weight: bold;
  color: $base-color;
  background: transparent;
  margin-top: 0.5px;
  opacity: 0.9;
}
.pr-btn {
  background: $base-color;
  color: white;
  font-size: 14px;
  border: 0;
  height: 40px;
  margin-top: 1rem;
}

.pr-btn:hover {
  background: #1212ff;
}

b {
  color: #000 !important;
}
.autre-btn {
  width: 18.7%;
}

@media only screen and (max-width: 760px) {
  .modalRgTi {
    width: 95%;
  }
  .autre-btn {
    width: 27.5%;
  }
}
</style>
